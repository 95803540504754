/* common */
* {
  word-break: keep-all;
}

/* Toast 컨테이너가 항상 최상단에 표시되도록 설정 */
.Toastify__toast-container {
  z-index: 9999 !important;
  margin-top: 60px !important;
}

.Toastify__toast {
  font-family: "Pretendard", sans-serif;
  font-size: 14px;
}
