/* common */
.title {
  width: 40vw;
  padding: 0 0 20px 0;
  margin: 60px 10vw;
  border-bottom: 2px #162b4f solid;
  color: #162b4f;
  font-size: 32px;
  font-weight: bold;
}

.cheeuforest .inner {
  position: relative;
}

/* mainvideo */
.cheeuforest .cf_main_bg {
  background-image: url(@asset/background/bg_cheeuforest.png);
  background-repeat: no-repeat;

  @media (min-width: 1941px) {
    background-size: 100%;
    overflow: hidden;
  }

  @media (min-width: 1025px) {
    background-position: center;
    height: 1760px;
    text-align: center;
    margin-top: 60px;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    background-image: none;
  }
  @media (max-width: 767px) {
    background-image: none;
    background-position: left;
    background-size: 130%;
    overflow: hidden;
  }
}

.cheeuforest .cf_title {
  color: #fff;
  @media (min-width: 1025px) {
    padding-top: 320px;
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    padding-top: 460px;
    background-image: url(@asset/background/bg_cheeuforest.png);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 130%;
    height: 922px;
    overflow: hidden;
  }

  @media (max-width: 767px) {
    padding-top: 291px;
    background-image: url(@asset/background/bg_cheeuforest.png);
    background-repeat: no-repeat;
    background-position: -80px 60px;
    background-size: 160%;
    height: 536px;
    overflow: hidden;
  }
}

/* icon */
.cheeuforest .cf_sub_logo {
  @media (min-width: 1419px) {
    width: 80vw;
  }
}
.cheeuforest .cf_title img#cf_logo {
  margin-bottom: 40px;

  @media (max-width: 1024px) and (min-width: 768px) {
    display: block;
    text-align: left;
    margin-left: 10vw;
  }

  @media (max-width: 767px) {
    width: 76px;
    height: 76px;
    display: block;
    text-align: left;
    margin-left: 10vw;
  }
}

/* title */
.cheeuforest #cf_title_01 {
  color: #fff;

  @media (min-width: 1025px) {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    font-size: 1rem;
    text-align: left;
    margin-left: 10vw;
  }

  @media (max-width: 767px) {
    font-size: 0.75rem;
    text-align: left;
    margin-left: 10vw;
  }
}

.cheeuforest #cf_title_02 {
  font-weight: bold;
  color: #fff;
  margin: 0 auto;

  @media (min-width: 1025px) {
    font-size: 3.25rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem;
    text-align: left;
    margin: 6px 10vw;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
    text-align: left;
    margin: 2px 10vw;
  }
}

.cheeuforestEng #cf_title_01 p {
  margin: 0 10vw;

  @media (max-width: 1024px) {
    margin-top: 10px;
    margin-left: 0;
  }
}

/* subtitle */
.cheeuforest .cf_subtitle {
  text-align: left;
  z-index: 1;

  @media (min-width: 1025px) {
    position: absolute;
    margin-top: 640px;
    margin-left: 10vw;
    margin-right: 10vw;
  }
  @media (max-width: 1024px) {
    position: inherit;
    color: #162b4f;
    background-color: #f8fbff;
  }
  @media (max-width: 1024px) {
    padding: 100px 10vw 50px;
  }
}
.cheeuforest #cf_subtitle_01 {
  font-weight: bold;

  @media (min-width: 1025px) {
    font-size: 3.75rem;
    color: #fff;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
}

.cheeuforest #cf_subtitle_02 {
  font-weight: bold;

  @media (min-width: 1025px) {
    font-size: 1.75rem;
    color: #fff;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.cheeuforest #cf_subtitle_03 {
  @media (min-width: 1025px) {
    font-size: 1.75rem;
    color: #fff;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.cheeuforest #cf_subtitle_04 {
  color: #fff;
  width: fit-content;
  padding: 6px 12px;
  background-color: #162b4fb7;

  @media (min-width: 1025px) {
    font-size: 1rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 0.75rem;
  }
  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
}

.cheeuforest #cf_logoset_w {
  position: absolute;

  @media (min-width: 1025px) {
    right: 0;
    margin-top: 100px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}
.cheeuforest #cf_logoset {
  @media (min-width: 1025px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    display: block;
    margin: 0 auto;
    width: 140px;
    height: 26px;
  }
}

/* service */
.cf_service {
  margin: 200px auto;
}

.cf_service #cf_service_title {
  margin-left: 10vw;
  font-weight: bold;
  font-size: 2rem;
  color: #162b4f;

  @media (max-width: 530px) {
    font-size: 1.5rem;
  }
}
.cf_service #cf_service_subtitle {
  margin: 0 10vw 100px;
  font-size: 1.75rem;
  color: #162b4f;

  @media (max-width: 530px) {
    font-size: 1.25rem;
  }
}

.cf_service_list {
  justify-content: center;

  @media (min-width: 1460px) {
    display: flex;
  }
}

.cf_service_list_img {
  padding: 0;
  @media (max-width: 1459px) {
    margin: 40px auto 0;
    text-align: center;
  }
}
.cf_service_list .cheeuforest_service_img {
  @media (max-width: 1459px) {
    margin: 0 auto;
    text-align: center;
  }
}

.cf_service_list img {
  width: 720px;
  @media (max-width: 780px) {
    width: 100%;
  }
}

.cf_service_list_text {
  width: 720px;
  padding: 40px 40px 0 40px;
  color: #162b4f;
  background-color: #bdd0ee2a;

  @media (max-width: 1460px) and (min-width: 780px) {
    margin: 0 auto;
    height: 40vh;
  }

  @media (max-width: 780px) {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 40px;
  }
}

.cf_service_list_title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;

  @media (max-width: 780px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  @media (max-width: 530px) {
    font-size: 1.5rem;
  }
}
.cf_service_list_subtitle {
  font-size: 2rem;
  margin-bottom: 20px;

  @media (max-width: 780px) {
    font-size: 1.5rem;
  }

  @media (max-width: 530px) {
    font-size: 1.25rem;
  }
}

.cf_service_list_body {
  font-size: 1.5rem;

  @media (max-width: 780px) {
    font-size: 1rem;
  }

  @media (max-width: 530px) {
    font-size: 0.75rem;
  }
}

/* program */
.cf_program {
  padding: 100px 0 200px;
  background-color: #e0e7f2;
}
.cf_program .inner {
  padding: 100px 0;
  margin: 100px 10vw 200px;
  background-color: #ffffff;

  @media (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
}

/* 공통-title */
.cf_program .title {
  display: flex;
  @media (max-width: 767px) {
    width: 60vw;
  }
}

.cf_program .cf_number {
  font-weight: bold;
  line-height: 60%;
  margin-right: 20px;
  font-size: 7.25rem;
  color: #184488;
  padding-top: 16px;

  @media (max-width: 767px) {
    font-size: 5rem;
    padding-top: 36px;
  }
}

.cf_program .cf_text {
  @media (min-width: 768px) {
    margin-left: 34px;
  }
  @media (max-width: 767px) {
    margin-left: 0;
  }
}
.cf_program .cf_text01 {
  font-weight: bold;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
    margin-top: 20px;
  }
}
.cf_program .cf_text02 {
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 2.25rem;
  }
  @media (max-width: 767px) {
    font-size: 1.75rem;
  }
}

/* subtitle */
.cf_program .subtitle {
  margin: 0px 10vw 100px;
}

.cf_program_title {
  font-weight: bold;
  color: #184488;
  @media (min-width: 768px) {
    font-size: 2.25rem;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
}
.cf_program_subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #162b4f;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

/* program01 */
.cf_program .cf_program1_img {
  margin: 0 auto;
  display: grid;

  @media (min-width: 1024px) {
    margin-top: 180px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1023px) {
    margin-top: 20px;
    grid-template-columns: 1fr;
  }
}

.cf_program .cf_program1_img div {
  display: flex;
  margin: 0 auto 20px;
}

.cf_program .cf_program_img {
  @media (min-width: 1023px) {
    width: 30vw;
  }
  @media (max-width: 1023px) {
    width: 60vw;
  }
}

.cf_program .cf_program1_img div img {
  text-align: center;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    width: 142px;
    height: 142px;
  }
  @media (max-width: 767px) {
    width: 88px;
    height: 88px;
  }
}

.cf_program .cf_program1_img div ul {
  text-align: left;
  padding: 0;
  padding-left: 2px;
}

.cf_program .cf_program1_img div .cheeuforest_program1_title {
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}
.cf_program .cf_program1_img div .cheeuforest_program1_text {
  margin-top: 3px;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
}
.cf_program .cf_program1_img div .cheeuforest_program1_number {
  background-color: #e6e6e6;
  font-weight: bold;
  border-radius: 30px;
  margin-top: 4px;
  text-align: center;
  padding-top: 1px;

  @media (min-width: 768px) {
    font-size: 1.25rem;
    width: 100px;
    height: 32px;
  }
  @media (max-width: 767px) {
    font-size: 0.75rem;
    width: 60px;
    height: 20px;
  }
}

/* target */
.cf_target #cf_service_subtitle {
  margin: 0px 10vw 200px 10vw;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.cf_target_img {
  text-align: center;
}
.cf_target_img p {
  margin: 40px 10vw 100px;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}
.cf_target_img span {
  font-weight: bold;
}
#cheeuforest__kiosk {
  @media (max-width: 767px) {
    width: 50vw;
  }
}
