/* common */
.title {
  width: 40vw;
  padding: 0 0 20px 0;
  margin: 60px 10vw;
  border-bottom: 2px #162b4f solid;
  color: #162b4f;
  font-size: 32px;
  font-weight: bold;
}

/* mainvideo */
.RandDEng {
  width: 100%;
  height: 100%;
  background-image: url(@asset/background/bg_Rd.png);
}

.RandDEng .rd_main_bg {
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 60px;

  @media (min-width: 1941px) {
    background-size: 100%;
    overflow: hidden;
  }

  @media (min-width: 1025px) {
    height: 1020px;
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    height: 922px;
  }
  @media (max-width: 767px) {
    background-position: center;
    background-size: 280%;
    overflow: hidden;
  }
}

.RandDEng .rd_title {
  color: #fff;
  @media (min-width: 1025px) {
    padding-top: 320px;
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    padding-top: 460px;
    height: 922px;
  }

  @media (max-width: 767px) {
    padding-top: 307px;
    height: 525px;
  }
}

.RandDEng .rd_title img#rd_logo {
  @media (max-width: 1024px) and (min-width: 768px) {
    display: block;
    text-align: left;
    margin-left: 10vw;
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    width: 76px;
    height: 76px;
    display: block;
    text-align: left;
    margin-left: 10vw;
  }
}

.RandDEng #rd_title_01 {
  color: #fff;

  @media (min-width: 1025px) {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    font-size: 1rem;
    text-align: left;
    margin-left: 10vw;
  }

  @media (max-width: 767px) {
    font-size: 0.75rem;
    text-align: left;
    margin-left: 10vw;
  }
}

.RandDEng #rd_title_02 {
  font-weight: bold;
  color: #fff;
  margin: 0 auto;

  @media (min-width: 1025px) {
    font-size: 3.25rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem;
    text-align: left;
    margin-left: 10vw;
    margin-top: 6px;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
    text-align: left;
    margin-left: 10vw;
    margin-top: 2px;
  }
}

/* pipeline */
.RandDEng .rd_pipeline {
  margin-top: -60px;
  background-color: #f5f9ff;
}
.RandDEng .rd_pipeline .inner {
  padding-top: 200px;
  padding-bottom: 200px;
}
.RandDEng .rd_pipeline .rd_pipeline_img {
  text-align: center;
}

.RandDEng .rd_pipeline .rd_pipeline_img .pipeline_img {
  margin: 100px auto;
  background-repeat: no-repeat;
  @media (min-width: 1399px) {
    width: 1380px;
    height: 780px;
    background-image: url(@asset/image/img_RandD_pipelineEng.png);
    background-size: cover;
  }
  @media (max-width: 1400px) {
    width: 660px;
    height: 630px;
    background-image: url(@asset/image/img_RandD_pipelineEng_768px.png);
    background-size: cover;
  }

  @media (max-width: 767px) {
    width: 340px;
    height: 500px;
    background-image: url(@asset/image/img_RandD_pipelineEng_390px.png);
    background-size: cover;
  }
}

/* paper */
.RandDEng .rd_paper {
  margin-top: -60px;
  background-color: #eef4ff;
}

.RandDEng .rd_paper .inner {
  padding-top: 200px;
  padding-bottom: 200px;
}
