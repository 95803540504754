/* src/ImageSlider.css */
/* 미디어 쿼리 추가 */
.carousel-indicators {
  z-index: 0;
}
.carousel-control-next,
.carousel-control-prev {
  z-index: 0;
}
.controlledcarousel_youtube .slider-image,
.vertical-image {
  width: auto;
  height: 35vw; /* 슬라이더 이미지 높이 조정 */
  background-position: center;

  @media (min-width: 1025px) {
    margin: 0 19vw;
  }

  @media (min-width: 768px) {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    width: 80vw;
    height: 45vw; /* 슬라이더 이미지 높이 조정 */
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.controlledcarousel_youtube .slider-image {
  width: 1100px;
  height: 68vh;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 1270px) {
    width: 720px;
    height: 44vh;
  }
}

.controlledcarousel_youtube .slider-image:hover {
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.controlledcarousel_youtube .image1 {
  background-image: url("@asset/youtube/youtube_img1.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.controlledcarousel_youtube .image1:hover {
  background-image: url("@asset/youtube/youtube_img1_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtube .image2 {
  background-image: url("@asset/youtube/youtube_img2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.controlledcarousel_youtube .image2:hover {
  background-image: url("@asset/youtube/youtube_img2_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtube .image3 {
  background-image: url("@asset/youtube/youtube_img3.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.controlledcarousel_youtube .image3:hover {
  background-image: url("@asset/youtube/youtube_img3_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtube .image4 {
  background-image: url("@asset/youtube/youtube_img4.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.controlledcarousel_youtube .image4:hover {
  background-image: url("@asset/youtube/youtube_img4_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtube .image5 {
  background-image: url("@asset/youtube/youtube_img5.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.controlledcarousel_youtube .image5:hover {
  background-image: url("@asset/youtube/youtube_img5_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtube .image6 {
  background-image: url("@asset/youtube/youtube_img6.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.controlledcarousel_youtube .image6:hover {
  background-image: url("@asset/youtube/youtube_img6_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

/* next-button */
.controlledcarousel_youtube span.carousel-control-next-icon {
  width: 60px;
  height: 60px;
  background-image: url(@asset/button/btn_arrowright_normal.png);
}
.controlledcarousel_youtube span.carousel-control-next-icon:hover {
  background-image: url(@asset/button/btn_arrowright_hover.png);
}

/* prev-button */
.controlledcarousel_youtube span.carousel-control-prev-icon {
  width: 60px;
  height: 60px;
  background-image: url(@asset/button/btn_arrowleft_normal.png);
}
.controlledcarousel_youtube span.carousel-control-prev-icon:hover {
  background-image: url(@asset/button/btn_arrowleft_hover.png);
}

.controlledcarousel_youtube div.carousel-indicators {
  bottom: -50px;
}

.controlledcarousel_youtube div.carousel-indicators button:not(:active) {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffffff;
}

.controlledcarousel_youtube div.carousel-indicators button.active {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #6b98e5;
}

.controlledcarousel_youtube .show-more-button {
  display: block;
  margin: 20px auto !important;
  width: 80vw;
  height: 5vh;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ceddf5;
  border: #ceddf5;
  color: #162b4f;
}

.controlledcarousel_youtube .show-more-button:hover {
  font-weight: normal;
  background-color: #162b4f;
  color: #fff;
}

/* 영어 버전 호버 이미지 스타일 */
.controlledcarousel_youtubeEng .image1:hover {
  background-image: url("@asset/youtube/youtube_imgEng1_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtubeEng .image2:hover {
  background-image: url("@asset/youtube/youtube_imgEng2_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtubeEng .image3:hover {
  background-image: url("@asset/youtube/youtube_imgEng3_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtubeEng .image4:hover {
  background-image: url("@asset/youtube/youtube_imgEng4_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtubeEng .image5:hover {
  background-image: url("@asset/youtube/youtube_imgEng5_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.controlledcarousel_youtubeEng .image6:hover {
  background-image: url("@asset/youtube/youtube_imgEng6_hover.png");
  background-size: cover;
  background-repeat: no-repeat;
}

/* 화면 너비가 767px 이하일 때 슬라이더를 숨기고 세로 이미지 리스트를 표시합니다 */
@media (max-width: 767px) {
  .controlledcarousel_youtube .carousel-slider {
    display: none;
  }
  /* 영상더보기 버튼 */
  .controlledcarousel_youtube button#btn-more-video {
    display: none;
  }
}
