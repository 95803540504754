.scroll__container {
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 1;
}

button {
  background: none;
}
#top {
  width: 60px;
  height: 60px;
  border: none;
  background-image: url(@asset/button/btn_arrowup_navy_normal.png);
  background-size: cover;
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;
}
#top:hover {
  background-image: url(@asset/button/btn_arrowup_orange.png);
  background-repeat: no-repeat;
  background-size: cover;
}
