/* form {
  margin: 200px 10vw;

  @media (max-width: 767px) {
    margin: 100px 2vw;
  }
} */

.p_form {
  margin: 0px 10vw;
  padding: 40px;
}

@media (max-width: 767px) {
  .p_form {
    margin: 100px 2vw;
  }
}

.form-control:first-child {
  color: #212529;
}

/* button */
button.btn.btn-primary {
  display: block;
  margin: 20px auto;
  height: 5vh;
  font-weight: bold;
  background-color: #ceddf5;
  border: none;
  color: #162b4f;
  @media (min-width: 768px) {
    width: 360px;
  }
  @media (max-width: 767px) {
    width: 70vw;
  }
}

button.btn.btn-primary:hover {
  font-weight: normal;
  background-color: #162b4f;
  border: none;
  color: #ffffff;
}

.custom-select-wrapper {
  position: relative;
}

.custom-select {
  -webkit-appearance: none; /* 기본 드롭다운 화살표 제거 */
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding-right: 50px; /* 화살표가 들어갈 공간 확보 */
}

.custom-select-wrapper::after {
  content: "▼"; /* 화살표 모양 */
  font-size: 12px;
  color: #555;
  border: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  pointer-events: none; /* 화살표가 클릭되지 않도록 */
}

.custom-select-wrapper select:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.col-md-6:first-child {
  margin-bottom: 1rem;
}
