* {
  list-style: none;
}
.MyAccordion .accordion {
  width: 80vw;
  margin: 0 auto;
}

.MyAccordion .accordion-item {
  border: none;
  margin-bottom: 10px;
}

/* button */
.MyAccordion button.accordion-button {
  padding: 20px 36px;
  border-radius: 4px;
  background-color: #fff;
}

.MyAccordion button.accordion-button:hover {
  border-radius: 4px;
  background-color: #6b98e5;
}

/* year */
.MyAccordion button.accordion-button:hover .history_year {
  color: #fff;
}
.MyAccordion .history_year {
  font-size: 2rem;
  color: #6b98e5;
  font-weight: bold;
}

.MyAccordion .history_year::after {
  color: #162b4f;
}

.MyAccordion .history_year span {
  color: #162b4f;
}

/* Accordion.Body */
.MyAccordion .accordion-body {
  padding: 20px 36px;
}

.MyAccordion div.history_list {
  display: flex;
  margin-left: 10px;
}

.MyAccordion ul {
  padding: 0;
}

.MyAccordion .history_month {
  margin-top: 6px;
  width: 48px;
  font-weight: bold;
  font-size: 2rem;
  color: #162b4f;

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.MyAccordion .history_contents {
  text-align: center;
  margin: auto 20px auto;
  font-size: 1.5rem;
  color: #6b98e5;
  text-align: left;
  line-height: 52px;

  @media (max-width: 1024px) and (min-width: 768px) {
    width: 66vw;
  }
  @media (max-width: 767px) and (min-width: 441px) {
    font-size: 1.25rem;
    line-height: 180%;
  }
  @media (max-width: 440px) {
    font-size: 0.8rem;
    line-height: 180%;
  }
}

span#colored {
  color: #4f596a;
}
