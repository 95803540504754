/* 기본스타일 */
/* common */

.title {
  width: 40vw;
  padding: 0 0 20px 0;
  margin: 60px 10vw;
  border-bottom: 2px #162b4f solid;
  color: #162b4f;
  font-size: 32px;
  font-weight: bold;
}

/* mainvideo */
.homeEng .mainvideo {
  background-image: url(@asset/logo/logo_MindsAI_blue_1020.gif);
  background-position: center;
  background-repeat: no-repeat;
  height: 1020px;
  text-align: center;
  margin-top: 60px;
  @media (min-width: 1941px) {
    background-size: 100%;
    overflow: hidden;
  }
}

.homeEng .mainvideo .inner {
  padding-top: 310px;
}

.homeEng .mainvideo .inner #mv_title {
  margin: 0 auto;
  width: 90vw;
  font-size: 3rem;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.homeEng .mainvideo .inner img#mv_logo_eng {
  width: 380px;

  @media (max-width: 520px) {
    width: 60vw;
  }
}

/* media */
.homeEng .media .swiper-slide {
  @media (max-width: 767px) {
    width: 70vw;
  }
}

/* ourmission */
.homeEng .ourmission {
  margin: 0 auto 100px;
  justify-content: center;
  text-align: center;
  position: relative;
}
.homeEng .ourmission .inner {
  margin: 0 8vw;
  white-space: pre-line;
}

.homeEng .ourmission #title-1 {
  margin-top: 200px;
  margin-bottom: 60px;
  font-weight: bold;
  color: #184488;

  @media (min-width: 768px) {
    font-size: 5rem;
  }
  @media (max-width: 767px) {
    font-size: 2.5rem;
  }
}

.homeEng .ourmission #title-2 {
  color: #162b4f;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.homeEng .ourmission #subtitle-1 {
  font-weight: bold;
  color: #162b4f;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
}
.homeEng .ourmission #subtitle-2 {
  color: #162b4f;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.homeEng .ourmission #img_ourmission {
  display: flex;
  margin: 60px auto 200px;
  width: 272px;
  height: 92px;
}

.homeEng .ourmission #btn_download_normal {
  width: 272px;
  height: 56px;
  background-image: url(@asset/button/btn_download_normal.png);
  background-repeat: no-repeat;
  margin: 90px auto;
}
.homeEng .ourmission #btn_download_normal:hover {
  background-image: url(@asset/button/btn_download_hover.png);
  cursor: pointer;
}

/* business */
.homeEng .business {
  background-color: #dbe7f9;
  padding: 200px 0;
}

.homeEng .business .business_contents {
  display: flex;
  margin: 0 10vw;

  @media (max-width: 1024px) {
    display: block;
  }
}

.homeEng .business .business_contents_inner {
  background-color: #ffffff90;
  padding: 24px 38px 47px 37px;
  width: 26vw;
  display: block;

  @media (max-width: 1024px) {
    margin-bottom: 20px;
    width: 80vw;
  }
}

.homeEng .business .business_contents_inner:not(:last-child) {
  @media (min-width: 768px) {
    margin-right: 15px;
  }
}

.homeEng .business .business_contents_title {
  color: #6b98e5;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.5rem;
    font-weight: bold;
  }
}
.homeEng .business .business_contents_subtitle {
  color: #184488;
  font-weight: bold;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
    margin-bottom: 10px;
  }
}
.homeEng .business .business_contents_text {
  color: #184488;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

/* history */
.homeEng .history {
  background-color: #f5f9ff;
  padding: 200px 0 200px 0;
}

/* partners */
.homeEng .partners {
  background-color: #ceddf5;
  padding: 200px 0 200px 0;
}
.homeEng .partners .inner {
  margin: 0 10vw;
}

.homeEng .partners p.partners_subtitle {
  color: #162b4f;

  border-radius: 4px;
  @media (min-width: 1025px) {
    font-size: 1.25rem;
  }
}
.homeEng .partners p.partners_subtitle:not(:first-child) {
  margin-top: 40px;
}

.homeEng .partners .partners_logo {
  display: grid;

  @media (min-width: 1441px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 80vw;
  }
  @media (max-width: 1440px) and (min-width: 967px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 80vw;
  }
  @media (max-width: 966px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 80vw;
  }
}

.homeEng .partners .partners_logo img {
  @media (max-width: 966px) {
    width: 36vw;
  }
}

/* media */
.homeEng .media {
  padding: 200px 0;
  background-color: #eef4ff;
  position: relative;
}

/* 더 보기 버튼 스타일 */
.homeEng .media #btn-more-video {
  position: absolute;
  top: 260px;
  right: 10vw;

  width: 168px;
  margin: 20px auto !important;
  height: 5vh;
  background-color: #ceddf5;
  border: #ceddf5;
  border-radius: 4px;
  color: #162b4f;
  font-weight: bold;
  cursor: pointer;
  /* z-index: 1; */

  @media (max-width: 767px) {
    display: none;
  }
}

.homeEng .media #btn-more-video:hover {
  background-color: #162b4f;
  color: #fff;
  font-weight: normal;
}

/* image */
.homeEng .media div.swiper-container {
  width: 80vw;
}

.homeEng .media .swiper-wrapper {
  transform: translate3d(0, 0, 0);
  margin: 40px 0;
}

.homeEng .media .swiper-slide {
  position: relative;
  margin: 0;
  padding-right: 2vw;
  width: 50vw;
  text-align: center;
  display: flex; /* 내용을 중앙정렬 하기위해 flex 사용 */
  align-items: center; /* 위아래 기준 중앙정렬 */
  justify-content: center; /* 좌우 기준 중앙정렬 */
  filter: blur(4px);
}

.homeEng .media .swiper-slide-active {
  justify-content: center;
  filter: none;
}

/*  */
/* slide img */
.homeEng .media .swiper-slide img {
  width: 100%; /* 이미지 크기를 슬라이드에 맞게 설정 */
}
.homeEng .media .img-hover {
  top: 0; /* 부모 요소의 맨 위에 위치 */
  left: 0; /* 부모 요소의 맨 왼쪽에 위치 */
  display: none; /* 호버 이미지를 기본적으로 숨김 */
  position: absolute; /* 절대 위치 지정 */
  padding-right: 2vw;
}
.homeEng .media .swiper-slide:hover .img-normal {
  display: none; /* 호버 시 기본 이미지 숨김 */
}

.homeEng .media .swiper-slide:hover .img-hover {
  display: block; /* 호버 시 호버 이미지 표시 */
  cursor: pointer;
}

/*  */
/* prev,next button */
.homeEng .media .swiper-container .swiper-button-prev,
.homeEng .media .swiper-container .swiper-button-next {
  width: 50px; /* 버튼 너비 설정 */
  height: 50px; /* 버튼 높이 설정 */
  background-image: none;
}

/* 공통적으로 들어가는 부분, 꼭 기본 navigation을 숨겨 줘야 한다. */
.homeEng .media .swiper-button-prev::after,
.homeEng .media .swiper-button-next::after {
  display: none;
}

/* 여기서부터 수정 */
.homeEng .media .swiper-button-prev,
.homeEng .media .swiper-button-next {
  /* 원하는 크기로 조절 */
  background-image: none;
}

/* 이미지가 위의 선택자의 사이즈를 따라하도록. */
.homeEng .media .swiper-button-prev > img,
.homeEng .media .swiper-button-next > img {
  position: absolute;
  width: 100%;
  background-color: #ffffff50;
  border-radius: 80%;
}

.homeEng .media .swiper-button-prev > img {
  left: 20px;
}

.homeEng .media .swiper-button-next > img {
  right: 10px;
}

.homeEng #prev-hover,
#next-hover {
  opacity: 0;
}

.homeEng .media .swiper-button-prev > img:hover #prev-hover {
  display: block;
}
.homeEng .media .swiper-button-prev:hover #prev-hover,
.homeEng .media .swiper-button-next:hover #next-hover {
  background-color: #ffffff50;
  border-radius: 50%;
  opacity: 1;
}

.homeEng .swiper-pagination {
  left: 22vw;
}

.homeEng.swiper-pagination-bullet {
  margin-left: 10px;
}

/* news */
.homeEng .news {
  padding: 200px 0;
  background-color: #eef4ff;
  position: relative;
}

/* tab */
.homeEng .tab-menu {
  display: flex;
  justify-content: left;
  padding-left: 10vw;
  margin-bottom: 20px;
}
.homeEng .tab-item {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 32px;
}
.homeEng .tab-item.active {
  font-weight: bold;
  color: #e65b53;
}

.homeEng .image-container {
  position: relative;
}
.homeEng .slider-image {
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease;
}

.homeEng .homecontainer .news .slider-image-hover {
  width: 100%;
  height: auto;
  position: absolute;
  margin-top: 100px;
  margin-bottom: 200px;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.homeEng .image-container:hover .slider-image-hover {
  opacity: 1;
}
.homeEng .image-container:hover .slider-image {
  opacity: 0;
}

/* button */
/* 공통적으로 들어가는 부분, 꼭 기본 navigation을 숨겨 줘야 한다. */
.homeEng .news .swiper-button-prev::after,
.homeEng .news .swiper-button-next::after {
  display: none;
}

/* 여기서부터 수정 */
.homeEng .news .swiper-button-prev,
.homeEng .news .swiper-button-next {
  /* 원하는 크기로 조절 */
  background-image: none;
}

/* 이미지가 위의 선택자의 사이즈를 따라하도록. */
.homeEng .news .swiper-button-prev > img,
.homeEng .news .swiper-button-next > img {
  position: absolute;
  background-color: #ffffff50;
  border-radius: 80%;
  bottom: 50px;
}

.homeEng .news .swiper-button-prev > img {
  left: 20px;
}

.homeEng .news .swiper-button-next > img {
  right: 10px;
}

.homeEng #prev-hover,
#next-hover {
  opacity: 0;
}

.homeEng .news .swiper-button-prev > img:hover #prev-hover {
  display: block;
}
.homeEng .news .swiper-button-prev:hover #prev-hover,
.homeEng .news .swiper-button-next:hover #next-hover {
  background-color: #ffffff50;
  border-radius: 80%;
  opacity: 1;
}

.homeEng .swiper-pagination {
  left: 22vw;
}

.homeEng .swiper-pagination-bullet {
  margin-left: 10px;
}
