/* src/Header.css */

header {
  top: 0;
  position: fixed;
  background-color: #ebf3ff;
  position: fixed;
  z-index: 11111;
  width: -webkit-fill-available;
  height: 60px;
}

.logo {
  margin: auto 0;
  width: 120px;
  display: block;
}

#logo1 {
  width: 120px;
}
#logo2 {
  width: 120px;
}

a.active {
  @media (max-width: 390px) {
    z-index: 111;
    width: 120px;
  }
}

header ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

header ul li {
  list-style: none;
}

header ul li a {
  color: #162b4f;
  font-weight: bold;
  text-decoration: none;
  font-size: 15px;
  width: fit-content;
}

.menu-icon {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
}
.close-icon {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}

/* 오른쪽 바가 되었을 때 */

/* main-menu */
header ul li a:hover:after {
  transform: scaleX(1);
}
header ul li a:after {
  display: block;
  content: "";
  border-bottom: solid 2px #162b4f;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

header ul li a.active:hover:after {
  transform: scaleX(1);
}
header ul li a.active:after {
  display: block;
  content: "";
  border-bottom: solid 2px #e65b53;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

/* 추가 */
header ul li a.active {
  color: #e65b53; /* 메인메뉴 선택 활성화된 링크의 색상을 변경 */
}

/* language */
header ul li#checked {
  color: #e65b53;
}
header ul li {
  font-weight: bold;
}
header ul li.active {
  color: #e65b53; /* 언어 선택 메뉴 활성화된 링크의 색상을 변경 */
}

/* language */
header ul li {
  cursor: pointer;
}

/* kor */
header ul li#kor:hover:after {
  transform: scaleX(1);
}
header ul li#kor:after {
  display: block;
  content: "";
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

header ul li#kor.active:hover:after {
  transform: scaleX(1);
}
header ul li#kor.active:after {
  display: block;
  content: "";
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
/* eng */
header ul li#eng:hover:after {
  transform: scaleX(1);
}
header ul li#eng:after {
  display: block;
  content: "";
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

header ul li#eng.active:hover:after {
  transform: scaleX(1);
}
header ul li#eng.active:after {
  display: block;
  content: "";
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

header .language-btn {
  display: none;
}

header .more-btn {
  display: none;
}

/* 사이즈 줄어들면 헤더가 숨겨짐 */
@media (min-width: 1210px) {
  header {
    padding: 0 2vw;
  }

  .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }
  header ul li {
    margin: 15px 10px;
  }
  #logo1 {
    display: none;
  }
}

@media (max-width: 1210px) {
  header {
    padding: 0 20px;
  }

  header .inner {
    margin: 10px 0;
    padding: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header ul li {
    border: 1px solid #d1d1d150;
  }

  #logo2 {
    display: none;
  }

  .nav-menu {
    position: fixed;
    margin-top: 0;
    top: 0;
    right: 0;
    height: 100%;
    height: 100%;
    width: 280px;
    background-color: #ffffff;
    flex-direction: column;
    align-items: flex-start;
    transform: translateX(100%);

    @media (max-width: 390px) {
      width: 100%;
    }
  }

  .nav-menu.open {
    transform: translateX(0);
  }

  .nav-menu ul {
    margin: 80px 0;
    width: 100%;
    display: block;

    @media (max-width: 1210px) {
      margin: 0;
    }
  }

  .nav-menu ul li {
    padding-left: 20px;
  }

  .nav-menu ul li#Home.item {
    margin-top: 60px;
    border-top: 1px solid #d1d1d150;
  }

  .nav-menu ul li a {
    display: block;
    width: fit-content;
    padding: 16px 0;
  }

  /* laguage */
  .nav-menu ul.language {
    display: flex;
  }
  .nav-menu ul.language li {
    width: 100%;
    padding: 16px 0 16px 20px;
  }

  .nav-menu ul li.middle {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .close-icon {
    display: block;
  }
}
