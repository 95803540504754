/* common */
.title {
  width: 40vw;
  padding: 0 0 20px 0;
  margin: 60px 10vw;
  border-bottom: 2px #162b4f solid;
  color: #162b4f;
  font-size: 2rem;
  font-weight: bold;
}

.mindsnaviEng .inner {
  position: relative;
}

/* mainvideo */
.mindsnaviEng .mm_main_bg {
  background-image: url(@asset/background/bg_mindsnavi.png);
  background-repeat: no-repeat;
  margin-top: 60px;
  width: 100%;

  @media (min-width: 1941px) {
    background-size: 100%;
    overflow: hidden;
  }

  @media (min-width: 1025px) {
    background-position: center;
    height: 1760px;
    text-align: center;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    background-image: none;
  }
  @media (max-width: 767px) {
    background-image: none;
  }
}

.mindsnaviEng .mm_title {
  @media (min-width: 1025px) {
    padding-top: 320px;
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    padding-top: 460px;
    background-image: url(@asset/background/bg_mindsnavi.png);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 200%;
    height: 922px;
    overflow: hidden;
  }

  @media (max-width: 767px) {
    padding-top: 220px;
    background-image: url(@asset/background/bg_mindsnavi.png);
    background-repeat: no-repeat;
    width: 100%;
    background-position: left;
    background-size: 210%;
    height: 480px;
    overflow: hidden;
  }
}

.mindsnaviEng .mm_title img#mm_logo {
  margin-bottom: 40px;

  @media (max-width: 1024px) and (min-width: 768px) {
    display: block;
    text-align: left;
    margin-left: 10vw;
  }

  @media (max-width: 767px) {
    width: 76px;
    height: 76px;
    display: block;
    text-align: left;
    margin-left: 10vw;
    margin-bottom: 10px;
  }
}

.mindsnaviEng .mm_title_01 {
  color: #fff;

  @media (min-width: 1025px) {
    line-height: 30px;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    line-height: 22px;
    font-size: 1rem;
    text-align: left;
    margin: 2px 10vw 0 10vw;
  }

  @media (max-width: 767px) {
    line-height: 14px;
    font-size: 0.75rem;
    text-align: left;
    margin: 16px 10vw 0 10vw;
  }
}

.mindsnaviEng #mm_title_02 {
  font-weight: bold;
  color: #fff;
  margin: 0 10vw;
  margin-bottom: 20px;

  @media (min-width: 1025px) {
    font-size: 3.25rem;
    line-height: 50px;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    font-size: 2rem;
    text-align: left;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 6px;
  }

  @media (max-width: 767px) {
    line-height: 20px;
    font-size: 1.25rem;
    text-align: left;
    margin: 2px 10vw 0 10vw;
  }
}

/* main_subtitle */
.mindsnaviEng .mm_subtitle {
  text-align: left;

  @media (min-width: 1025px) {
    position: absolute;
    margin-top: 640px;
    margin-left: 10vw;
    margin-right: 10vw;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 100px 10vw 50px;
    color: #162b4f;
    background-color: #f8fbff;
  }
  @media (max-width: 767px) {
    padding: 40px 10vw 36px;
    color: #162b4f;
    background-color: #f8fbff;
  }
}

.mindsnaviEng #mm_subtitle_01 {
  font-weight: bold;

  @media (min-width: 1025px) {
    font-size: 3.75rem;
    color: #fff;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
}

.mindsnaviEng #mm_subtitle_02 {
  @media (min-width: 1025px) {
    font-weight: bold;
    font-size: 1.75rem;
    color: #fff;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
}

.mindsnaviEng .mm_sub_logo {
  @media (min-width: 1419px) {
    width: 80vw;
  }
}

.mindsnaviEng #mm_logoset_w {
  position: absolute;
  @media (min-width: 1025px) {
    right: 0;
    margin-top: 100px;
  }
  @media (max-width: 1418px) {
    margin-top: 0;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}

.mindsnaviEng #mm_logoset {
  @media (min-width: 1025px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    display: block;
    margin: 0 auto;
    width: 140px;
    height: 26px;
  }
}

/* service */
.mindsnaviEng .mm_service {
  background-color: #fff;
}
.mindsnaviEng .mm_service .inner {
  margin: 200px auto;
}
.mindsnaviEng .mm_service .service_contents {
  @media (min-width: 1440px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1439px) and (min-width: 821px) {
    justify-content: left;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10vw;
  }
  @media (max-width: 820px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.mindsnaviEng .mm_service figure {
  text-align: center;
  @media (min-width: 821px) {
    margin-top: 100px;
    height: 240px;
  }
  @media (max-width: 820px) {
    margin-top: 0;
  }
}

.mindsnaviEng .mm_service .inner figure img.service_process {
  @media (min-width: 1440px) {
    width: 10vw;
    margin: 10px;
  }
  @media (max-width: 1439px) and (min-width: 821px) {
    width: 20vw;
  }
  @media (max-width: 820px) {
    width: 210px;
    margin: 10px;
  }
}

.mindsnaviEng .mm_service .inner figure img.service_arrow {
  height: 240px;
  @media (min-width: 1440px) {
    margin-top: -30px;
  }
  @media (max-width: 1439px) and (min-width: 822px) {
    margin-top: 30px;
  }
  @media (min-width: 821px) {
    height: 16vw;
  }
  @media (max-width: 820px) {
    display: none;
  }
}

.mindsnaviEng .service_process_text {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: visible;

  @media (max-width: 1440px) and (min-width: 820px) {
    width: 20vw;
  }

  @media (max-width: 820px) {
    margin: 0 auto;
  }
}

/* program list */

.mindsnaviEng .mm_program_list {
  background-color: #e0e7f250;
}
.mindsnaviEng .mm_program_list .inner {
  padding: 200px 0;
}
.mindsnaviEng .mm_program_list_contents {
  margin: 0 auto;
  justify-content: center;
  @media (min-width: 1369px) {
    display: flex;
  }
  @media (max-width: 1368px) {
    display: block;
    justify-content: center;
  }
}
.mindsnaviEng .mm_program_list_program {
  background-color: #fff;
  width: 30vw;
  margin-right: 10px;
  padding: 10px 20px;
  display: flex;

  @media (max-width: 1368px) {
    width: 50vw;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  @media (max-width: 640px) {
    width: 80vw;
  }
}

.mindsnaviEng .mm_program_list_program .mm_program_list_title {
  width: 44px;
  color: #eff3f8;
  font-weight: bold;
  font-size: 66px;
  margin: auto 0;
  padding: 0;

  @media (max-width: 460px) {
    font-size: 3rem;
  }
}
.mindsnaviEng .mm_program_list_program .mm_program_list_subtitle {
  color: #162b4f;
  margin: auto 0;
  padding: 0;
  padding-left: 20px;
}
.mindsnaviEng
  .mm_program_list_program
  .mm_program_list_subtitle
  .mm_program_list_subtitle_01 {
  font-size: 1.5rem;

  @media (max-width: 460px) {
    font-size: 1rem;
  }
}
.mindsnaviEng
  .mm_program_list_program
  .mm_program_list_subtitle
  .mm_program_list_subtitle_02 {
  font-size: 1.25rem;
  font-weight: bold;
}

/* 공통 */
.mindsnaviEng .mm_program .inner {
  padding: 200px 0;

  @media (max-width: 768px) {
    padding: 100px 0;
  }
}

.mindsnaviEng .mm_program .contents {
  padding-left: 10vw;
  padding-right: 10vw;

  @media (min-width: 768px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  @media (max-width: 767px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

/* 공통-title */
.mindsnaviEng .mm_program .title {
  display: flex;
  margin-top: 0;
  @media (max-width: 1024px) and (min-width: 768px) {
    width: 50vw;
  }

  @media (max-width: 767px) {
    width: 80vw;
  }
}
.mindsnaviEng .mm_program .title--number {
  font-size: 7.25rem;
  color: #184488;
  margin-top: 20px;

  @media (max-width: 767px) {
    font-size: 5.5rem;
    padding-top: 30px;
  }
}
.mindsnaviEng .mm_program .title--text {
  margin-left: 34px;
}
.mindsnaviEng .mm_program .title--text--first {
  font-size: 2.25rem;
  font-weight: bold;
  margin: 0;
  margin-top: 58px;

  @media (max-width: 767px) {
    font-size: 2rem;
  }
}
.mindsnaviEng .mm_program #title--text--firstfirst {
  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
}
.mindsnaviEng .mm_program .title--text--second {
  font-size: 1.5rem;
  margin: 0;
  margin-top: -10px;
  @media (min-width: 768px) {
    margin-top: 10px;
    line-height: 30px;
  }
  @media (max-width: 767px) {
    margin-top: 2px;
    font-size: 1rem;
    line-height: 22px;
  }
}

/* program01 */
.mindsnaviEng #mm_program_01 {
  position: relative;
  background-color: #f2f2f2;
}

/* program01-contents */
.mindsnaviEng #mm_program_01 .inner .contents .contents__01 {
  margin-top: 60px;
  font-weight: bold;
  color: #184488;

  @media (min-width: 1025px) {
    font-size: 2.25rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
}
.mindsnaviEng #mm_program_01 .inner .contents .contents__02 {
  margin-top: -20px;
  @media (min-width: 1670px) {
    width: 40vw;
  }
  @media (min-width: 1025px) {
    font-size: 1.25rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1rem;
  }
  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
}
.mindsnaviEng #mm_program_01 .inner .contents .contents__03 {
  font-weight: bold;
  @media (min-width: 1670px) {
    width: 40vw;
  }
  @media (min-width: 1025px) {
    font-size: 1.5rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.25rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}
.mindsnaviEng #mm_program_01 .inner .contents .contents__04 {
  font-weight: bold;
  @media (min-width: 1670px) {
    width: 40vw;
  }

  @media (min-width: 1025px) {
    font-size: 1.5rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.25rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

/* program01-prove */
.mindsnaviEng #mm_program_01 .inner .contents .prove {
  margin-top: 100px;
}

.mindsnaviEng #mm_program_01 .inner .contents .contents--emphasisbox {
  margin-top: 10px;
  background-color: rgba(22, 43, 79, 20%);
  color: #162b4f;
  font-weight: bold;
  width: fit-content;
  padding: 8px 10px 7px;
}

.mindsnaviEng #mm_program_01 .inner .contents .prove-contents {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    padding: 0 auto;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 0 auto;
  }
}

.mindsnaviEng #mm_program_01 .inner .contents .prove-contents div {
  background-color: #fff;
  display: flex;
  padding: 10px;
  @media (max-width: 767px) {
    padding-right: 20px;
  }
}
.mindsnaviEng #mm_program_01 .inner .contents .prove-contents img {
  width: 74px;
  height: 74px;
  text-align: center;
}
.mindsnaviEng #mm_program_01 .inner .contents .prove-contents ul {
  margin: 0;
  padding: 0;
  padding-left: 2px;
}

.mindsnaviEng #mm_program_01 .inner .contents .prove-contents .prove_title {
  font-weight: bold;
  padding-top: 6px;
  font-size: 1.25rem;

  @media (max-width: 560px) {
    font-size: 1rem;
  }
}

.mindsnaviEng #mm_program_01 .inner .contents .prove-contents .prove_subtitle {
  padding-top: 6px;
  font-size: 1.25rem;

  @media (max-width: 560px) {
    font-size: 0.75rem;
  }
}

/* mockup */
.mindsnaviEng #program01--mockup {
  @media (min-width: 1670px) {
    position: absolute;
    top: 280px;
    right: 120px;
  }
  @media (min-width: 768px) and (max-width: 1669px) {
    display: block;
    margin-left: 10vw;
  }
  @media (max-width: 767px) {
    margin-left: 0;
    width: 90%;
  }
}

/* program02 */
.mindsnaviEng #mm_program_02 {
  position: relative;
  background-color: #e0e7f2;
}

/* program02-contents */
.mindsnaviEng #mm_program_02 .inner .contents .contents__01 {
  margin-top: 60px;
  font-weight: bold;
  color: #184488;
  @media (min-width: 1670px) {
    width: 40vw;
  }
  @media (min-width: 1025px) {
    font-size: 2.25rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
}

.mindsnaviEng #mm_program_02 .inner .contents .contents__03 {
  font-weight: bold;
  @media (min-width: 1741px) {
    width: 40vw;
  }
  @media (min-width: 1025px) {
    font-size: 1.5rem;
    margin-bottom: 100px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.25rem;
    margin-bottom: 100px;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
    margin-bottom: 20px;
  }
}
.mindsnaviEng #mm_program_02 .inner .contents .contents__04 li {
  font-weight: bold;
  list-style-type: disc;
  margin-bottom: 20px;
  @media (min-width: 1741px) {
    width: 40vw;
  }
  @media (min-width: 1025px) {
    font-size: 1.5rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.25rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

/* mockup */
.mindsnaviEng #program02--mockup {
  @media (min-width: 1741px) {
    position: absolute;
    top: 340px;
    right: 120px;
  }
  @media (min-width: 768px) and (max-width: 1740px) {
    display: block;
    margin-left: 10vw;
  }
  @media (max-width: 767px) {
    margin-left: 0;
    margin-bottom: 20px;
    width: 90%;
  }
}

/* program03 */
.mindsnaviEng #mm_program_03 {
  position: relative;
  background-color: #f7f8fb;
}

/* program03-contents */
.mindsnaviEng #mm_program_03 .inner .contents .contents__03 {
  font-weight: bold;
  margin-bottom: 100px;

  @media (min-width: 1025px) {
    font-size: 1.5rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.25rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}
.mindsnaviEng #mm_program_03 .inner .contents .contents__03 li {
  font-weight: bold;
  list-style-type: disc;
  margin-bottom: 20px;

  @media (min-width: 1025px) {
    font-size: 1.5rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.25rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}
.mindsnaviEng #mm_program_03 .inner .contents .contents--emphasisbox {
  background-color: rgba(22, 43, 79, 20%);
  color: #162b4f;
  font-weight: bold;
  width: fit-content;
  padding: 8px 10px 7px;

  @media (min-width: 1025px) {
    margin-top: 10px;
  }
  @media (max-width: 1024px) {
    margin-top: -60px;
  }
}

.mindsnaviEng #program03--mockup {
  margin: 0 auto;
  display: block;
  @media (min-width: 1025px) {
    width: 60vw;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 80vw;
  }
  @media (max-width: 767px) {
    width: 80vw;
  }
}
