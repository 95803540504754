@font-face {
  font-family: "Pretendard-Regular";
  src: url("./fonts/Pretendard-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: "Pretendard-Regular";
}
