.PaperEng .paper_container {
  margin: 100px auto;
  margin-left: 10vw;
  padding: 0;
}

/* 화면크기가 클 때 */
.PaperEng .paper_container .react-multi-carousel-list.container {
  margin: 0;
  padding: 0;
  width: 80vw;
}
.PaperEng .paper_container .react-multi-carousel-track div {
  margin: 40px auto;
  position: relative;
  padding: 30px;
  cursor: pointer;
  background-color: #fff;
  width: 360px;
  height: 480px;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.2),
    0 1px rgba(0, 0, 0, 0.1);
}

.PaperEng .paper_container .react-multi-carousel-item {
  display: flex;
}

/* 화면크기가 작을 때 */
.PaperEng .paper_container .react-multi-carousel-track div:hover {
  background-color: #6b98e530;
}
.PaperEng .paper_container .mb-3 {
  margin: 0 !important;
}
.PaperEng .paper_container .mb-3.col-12 div {
  margin-top: 20px;
  width: 80vw;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
}
.PaperEng .paper_container .mb-3.col-12 div:hover {
  background-color: #6b98e530;
}

.PaperEng .paper_container h2.paper-title {
  position: inherit;
  font-size: 1.5rem; /* 제목의 폰트 크기 */
  color: #6b98e5; /* 제목의 색상 */
  margin-bottom: 20px; /* 제목과 부제목 사이의 간격 */

  @media (max-width: 768px) {
    padding: 20px 20px 0;
    font-size: 1rem; /* 제목의 폰트 크기 */
  }
}

.PaperEng .paper_container h1.paper-subtitle {
  height: 34vh;
  position: inherit;
  font-size: 1.625rem; /* 제목의 폰트 크기 */
  font-weight: bold;
  color: #162b4f; /* 제목의 색상 */
  margin-bottom: 10px; /* 제목과 부제목 사이의 간격 */

  @media (max-width: 768px) {
    padding: 0 20px;
    height: 10vh;
    font-size: 1.25rem; /* 제목의 폰트 크기 */
  }
  @media (max-width: 520px) {
    font-size: 1rem; /* 제목의 폰트 크기 */
  }
}

.PaperEng .paper_container p.paper-body {
  position: inherit;
  font-size: 1.125rem; /* 제목의 폰트 크기 */
  color: #162b4f; /* 제목의 색상 */
  text-align: right;
  margin-bottom: 0;

  @media (max-width: 768px) {
    padding: 0 20px;
    font-size: 1rem; /* 제목의 폰트 크기 */
  }
}

/* tab */
.PaperEng .paper_container .nav.nav-tabs {
  width: 80vw;
}
.PaperEng .paper_container .nav-item .nav-link {
  text-align: center;
  color: #162b4f;

  @media (min-width: 990px) {
    font-size: 2rem;
    width: 10vw;
  }

  @media (max-width: 989px) and (min-width: 780px) {
    font-size: 1.5rem;
    width: 10vw;
  }

  @media (max-width: 779px) {
    font-size: 1rem;
    width: 20vw;
  }
}

.PaperEng .paper_container .nav-item .nav-link:hover {
  color: #e65b53;
  background-color: #ffffff7c;
  border: none;
}
.PaperEng .paper_container .nav-item .nav-link.active {
  font-weight: bold;
  color: #e65b53;
}

/* 화면에 따라 세로 가로 정렬 다르게 */
.PaperEng div.paper_container.container {
  width: 80vw;
}
.PaperEng .paper_container .col {
  width: 80vw;
}
.PaperEng .paper_container .carousel-view {
  display: block; /* 781px 이상에서는 슬라이더 보이기 */

  @media (max-width: 768px) {
    display: none; /* 780px 이하에서는 슬라이더 숨기기 */
  }
}

.PaperEng .paper_container .list-view {
  @media (min-width: 769px) {
    display: none; /* 781px 이상에서는 리스트형 뷰 숨기기 */
  }

  @media (max-width: 768px) {
    display: block; /* 780px 이하에서는 리스트형 뷰 사용 */
  }
}

/* button */
/* next-button */
.PaperEng
  .paper_container
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  width: 60px;
  height: 60px;
  background: none;
  background-image: url(@asset/button/btn_arrowright_normal.png);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.PaperEng
  .paper_container
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:before {
  display: none;
}
.PaperEng
  .paper_container
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:hover {
  background-image: url(@asset/button/btn_arrowright_hover.png);
  background-size: cover;
}

/* prev-button */
.PaperEng
  .paper_container
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  width: 60px;
  height: 60px;
  background: none;
  background-image: url(@asset/button/btn_arrowleft_normal.png);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.PaperEng
  .paper_container
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:before {
  display: none;
}
.PaperEng
  .paper_container
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:hover {
  background-image: url(@asset/button/btn_arrowleft_hover.png);
  background-size: cover;
}

/* 더보기 버튼 */
.PaperEng .paper_container button.mb-3 {
  margin: 20px auto !important;
  height: 5vh;
  background-color: #ceddf5;
  border: #ceddf5;
  color: #162b4f;
  font-weight: bold;
}
.PaperEng .paper_container button.mb-3:hover {
  background-color: #162b4f;
  color: #fff;
  font-weight: normal;
}
.PaperEng .paper_container .mb-3.btn.btn-primary {
  width: 80vw;
}
