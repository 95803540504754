/* slide-image */
/* div.carousel.slide{
    width: 80vw;
} */
.ControlledCarousel div.carousel-inner {
  width: 60vw;
  margin: 100px auto;
}
div.carousel-indicators {
  width: 80vw;
  margin: 0 auto;
}

/* next-button */
span.carousel-control-next-icon {
  background-image: url(@asset/button/btn_arrowright_normal.png);
  @media (min-width: 661px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 660px) and (min-width: 391px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 390px) {
    width: 30px;
    height: 30px;
  }
}
span.carousel-control-next-icon:hover {
  background-image: url(@asset/button/btn_arrowright_hover.png);
}

/* prev-button */
span.carousel-control-prev-icon {
  background-image: url(@asset/button/btn_arrowleft_normal.png);
  @media (min-width: 661px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 660px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 390px) {
    width: 30px;
    height: 30px;
  }
}
span.carousel-control-prev-icon:hover {
  background-image: url(@asset/button/btn_arrowleft_hover.png);
}
