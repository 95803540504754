/* footer */
.footer {
  z-index: 1000;
  background-color: #162b4f;
  color: #fff;
  padding: 10px 0;
  bottom: 0;
}

footer .inner ul {
  list-style: none;
  margin-right: 32px;

  @media (min-width: 1189px) {
    display: flex;
  }
}

footer .inner ul li.popup_TermsModal {
  font-weight: bold;
}
footer .inner ul li.popup_TermsModal:hover {
  color: #e65b53;
}
footer .inner ul li.popup_PolicyModal {
  font-weight: bold;
}
footer .inner ul li.popup_PolicyModal:hover {
  color: #e65b53;
}

footer .inner ul li:not(:last-child) {
  margin-right: 10px;
}

footer .logo {
  margin: 20px 32px;
}

.footer_icons {
  display: flex;
  justify-content: space-between;

  @media (max-width: 390px) {
    display: block;
  }
}
.footer_icons_group {
  margin-right: 32px;

  @media (max-width: 390px) {
    margin-left: 22px;
  }
}
.footer_icons_group a {
  text-decoration: none;
}

footer .icon {
  margin: 20px 10px;
  width: 32px;
}

footer p {
  margin-left: 32px;
  margin-right: 32px;
}
footer p:not(:last-child) {
  margin-top: 40px;
}

@media (max-width: 790px) {
  footer .inner ul li {
    font-size: 12px;
  }
  footer p {
    font-size: 12px;
    margin: 32px;
  }
}
@media (max-width: 1188px) {
  .line {
    display: none;
  }
}

#popup {
  z-index: 9999;
}
#popup_title {
  margin-bottom: 20px;
}
#popup_close {
  margin-top: 40px;
  width: 80px;
  height: 40px;
  margin-left: 50vw;
  border: none;
  background-color: #162b4f;
  color: #fff;
  border-radius: 4px;
}
#popup_close:hover {
  background-color: #e65b53;
}
