/* common */
.title {
  width: 40vw;
  padding: 0 0 20px 0;
  margin: 60px 10vw;
  border-bottom: 2px #162b4f solid;
  color: #162b4f;
  font-size: 32px;
  font-weight: bold;
}

/* mainvideo */
.cheeucounsel .cc_main_bg {
  background-image: url(@asset/background/bg_cheeucounsel.png);
  background-repeat: no-repeat;

  @media (min-width: 1941px) {
    background-size: 100%;
    overflow: hidden;
  }

  @media (min-width: 1025px) {
    background-position: center;
    height: 1760px;
    text-align: center;
    margin-top: 60px;
  }
  @media (max-width: 1024px) {
    background-image: none;
  }
}

.cheeucounsel .cc_title {
  color: #fff;
  padding-top: 320px;

  @media (max-width: 1024px) and (min-width: 768px) {
    background-image: url(@asset/background/bg_cheeucounsel.png);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 130%;
    height: 922px;
    overflow: hidden;
    padding-top: 600px;
  }

  @media (max-width: 767px) {
    background-image: url(@asset/background/bg_cheeucounsel.png);
    background-repeat: no-repeat;
    background-position: -20px left;
    background-size: 180%;
    height: 525px;
    overflow: hidden;
  }
}

/* icon */
.cheeucounsel .cc_title img#cc_logo {
  margin-bottom: 40px;

  @media (max-width: 1024px) and (min-width: 768px) {
    display: block;
    text-align: left;
    margin-left: 10vw;
  }

  @media (max-width: 767px) {
    width: 76px;
    height: 76px;
    display: block;
    text-align: left;
    margin-left: 10vw;
  }
}

.cheeucounsel #cc_title_01 {
  color: #fff;

  @media (min-width: 1025px) {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    font-size: 1rem;
    text-align: left;
    margin-left: 10vw;
  }

  @media (max-width: 767px) {
    font-size: 0.75rem;
    text-align: left;
    margin-left: 10vw;
  }
}

.cheeucounsel .cc_title #cc_title_02 {
  font-weight: bold;
  color: #fff;
  margin: 0 auto;

  @media (min-width: 1025px) {
    font-size: 3.25rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem;
    text-align: left;
    margin-left: 10vw;
    margin-top: 6px;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
    text-align: left;
    margin-left: 10vw;
    margin-top: 2px;
  }
}

/* subtitle */
.cheeucounsel .cc_subtitle {
  text-align: left;

  @media (min-width: 1025px) {
    position: absolute;
    margin-top: 640px;
    margin-left: 10vw;
    margin-right: 10vw;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 100px 10vw 50px;
    color: #162b4f;
    background-color: #f8fbff;
  }
  @media (max-width: 767px) {
    padding: 40px 10vw 36px;
    color: #162b4f;
    background-color: #f8fbff;
  }
}

.cheeucounsel #cc_subtitle_01 {
  font-weight: bold;

  @media (min-width: 1025px) {
    font-size: 3.75rem;
    color: #fff;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
}

.cheeucounsel #cc_subtitle_02 {
  font-weight: bold;

  @media (min-width: 1025px) {
    font-size: 1.75rem;
    color: #fff;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.cheeucounsel #cc_subtitle_03 {
  @media (min-width: 1025px) {
    font-size: 1.75rem;
    color: #fff;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

/* button */
#btn_apply {
  margin-top: 40px;

  width: 220px;
  margin: 20px auto !important;
  padding: 0 10px;
  height: 5vh;
  background-color: #ceddf5;
  border: #ceddf5;
  border-radius: 4px;
  color: #162b4f;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;

  @media (min-width: 768px) and (max-width: 1024px) {
    margin: 100px 0;
  }
  @media (max-width: 767px) {
    margin: 50px 0 100px;
  }
}
#btn_apply:hover {
  background-color: #162b4f;
  color: #fff;
  cursor: pointer;
  font-weight: normal;
}
